$( document ).on('turbolinks:load', () => {
  if (sessionStorage.getItem('last_power_judicial_request')) {
    if(JSON.parse(sessionStorage.getItem('last_power_judicial_request')).not_seen) {
      $('#open-power-judicials-modal-btn').find('.count-notifications').removeClass('d-none')
    }
  }
  // DROP DOWN TABLE
  $('.table-responsive').on('shown.bs.dropdown', function (e) {
    let $table = $(this);
    let $menu = $(e.target).find('.dropdown-menu');
    const tableOffsetHeight = $table.offset().top + $table.height();
    const menuOffsetHeight = $menu.offset().top + $menu.outerHeight(true);

    if (menuOffsetHeight > tableOffsetHeight) {
      $table.css("padding-bottom", menuOffsetHeight - tableOffsetHeight);
      $('.table-responsive').css('overflow-y', 'hidden')
    };
  })
  
  $('.table-responsive').on('hide.bs.dropdown', () => {
    $('.table-responsive').css('padding-bottom', 0)
    $('.table-responsive').css('overflow-y', 'auto')
  });

  $(document).on('select2:open', (event) => {
    $(document).find(`[aria-controls=select2-${event.target.id}-results]`)[0]?.focus()
  });

  $('body').on('click', '.link-download-archive', function (e) {
    e.preventDefault();
    let url = $(this).data('url')
    window.open(url)
  })

  $('body').on("click", ".link-show-document", function(){
    let close_icon = $('#close-document-button > span > svg > path')
    close_icon.attr("fill",'#0055B8')

    $('#document-display-div').html($('<embed></embed>', {
        width: "100%", height: "50vh",
        src : this.dataset.url,
        id: "document-opener"
    }))
    $('#download-document').data('url',this.dataset.url)
    if (this.dataset.title) {
      $('#show-document-title').text(this.dataset.title)
    }
    $('#show-document-modal').modal('toggle')
  })

  $('body').on('mouseenter mouseleave', '.popover-on-hover', function(event) {
    const popoverId = $(this).data('popover-id')

    if (event.type === 'mouseenter') {
      $(this).popover('show')
      $(this).data('popover-id', $(this).attr('aria-describedby'))
      return
    }

    if ($(event.relatedTarget).parents(`#${ popoverId }`).length || $(event.relatedTarget).attr('id') === popoverId) return

    setTimeout(() => {
      $(this).popover('hide')
    }, $(this).data('timeout') || 0)
  })

  $('body').on('mouseleave', '.popover', function(event) {
    if ($(event.relatedTarget).data('popover-id') === $(this).attr('id')) return
    $(this).popover('hide')
  })

  $('body').on('click', '.popover-on-hover', function(e) {
    const isMobile = $('#toggle-sidebar-mobile').css('display') === 'block';
    if (isMobile) {
      $(this).popover('hide')
    }
  })

  $('body').on('click', '.active-loader', function() {
    $('.loader').fadeIn()
  })

  $('body').on('input', '.input-group-prefix', function () {
    const $input = $(this).find('input')
    const prefix = $(this).data('prefix')

    if ($input.val().length > 0) {
      $(this).find('.input-group-text').text(prefix)
    } else {
      $(this).find('.input-group-text').text('')
    }
  })

  $('body').on('click', '.check-animate-radio', function () {
    const $checkBox = $(this).find('input[type=checkbox]')
    const $label = $(this).find('label')

    if ($checkBox.prop('checked')) {
      $checkBox.prop('checked', false)
      $checkBox.val(false)
      $label.removeClass('active')
    } else {
      $checkBox.prop('checked', true)
      $checkBox.val(true)
      $label.addClass('active')
    }
  })

  $('body').on('keyup keypress', '.enter-prevent', function(e) {
    const keyCode = e.keyCode || e.which;
    const $form = $(this);

    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }

  });

  $('body').on('click', '.form-check-label', function(e) {
    const $checkBox = $(this).siblings('input[type=checkbox]')
    const isChecked = $checkBox.prop('checked')

    if (isChecked) {
      $(this).prop('checked', false);
      $checkBox.val(false)
    } else {
      $(this).prop('checked', true);
      $checkBox.val(true)
    }
  })

  $('body').on('change', '.form-submit-on-change', function() {
    // if datepicker range calendar ready we just rigger the form (db: deals_backoffice)
    if ($(this).attr("data-calendar-datetimepicker-r") == "yes" || $(this).attr("last-input-change-id") != "created_at") {
      $(this).find(':submit').removeAttr('disabled').trigger('click')
    }
  })

  $('body').on('change', '.input-submit-on-change', function() {
    if($(this).hasClass('change-client') ){
      if(confirm("¿Estás seguro de cambiar al cliente? Al realizar esta acción se eliminará todo el avance.")){
        $(this).parents('form').find(':submit').trigger('click')
      }else{
        $(this).val('')
      }
    }else{
      $(this).parents('form').find(':submit').trigger('click')
    }
  })

  $('body').on('change', '.dual-checkbox', function(event) {
    const $checkboxGroup = $(this).closest('.dual-checkbox-group')
    const $check = $checkboxGroup.find('.check')
    const $uncheck = $checkboxGroup.find('.uncheck')
    const targetId = $(this).data('target')
    const $target = $(targetId)
    const isChecked = $(this).hasClass('check')
    $check.val(isChecked).prop('checked', isChecked)
    $uncheck.val(!isChecked).prop('checked', !isChecked)
    $target.val(isChecked).prop('checked', isChecked).trigger("change")
  })

  $('body').on('change', '.dual-checkbox-deal', function(event) {

    const $checkboxGroup = $(this).closest('.dual-checkbox-group')
    const $check = $checkboxGroup.find('.check')
    const $uncheck = $checkboxGroup.find('.uncheck')
    const $returned = $checkboxGroup.find('.returned')
    const $bypassed = $checkboxGroup.find('.bypassed')

    const targetId = $(this).data('target')
    const $target = $(targetId)
    $('#ready_for_save').val('true')
    const isChecked = $(this).data('check')
    if ($(this).hasClass('check')){
      $check.prop('checked', true)
      $uncheck.prop('checked', false)
      $returned.prop('checked', false)
      $bypassed.prop('checked', false)
    }else if($(this).hasClass('uncheck')){
      $uncheck.prop('checked', true)
      $check.prop('checked', false)
      $returned.prop('checked', false)
      $bypassed.prop('checked', false)
    } else if($(this).hasClass('returned')){
      $uncheck.prop('checked', false)
      $check.prop('checked', false)
      $returned.prop('checked', true)
      $bypassed.prop('checked', false)
    } else{
      $uncheck.prop('checked', false)
      $check.prop('checked', false)
      $returned.prop('checked', false)
      $bypassed.prop('checked', true)
    }
    $target.val(isChecked)
  })

  $('body').on('input', '.miles', function() {
    $(this).mask("000.000.000.000", {reverse: true});
  });

  $('body').on('input', '.days', function() {
    $(this).mask("000.000", {reverse: true})
  });

  $('body').on('input', '.decimal', function() {
    $(this).mask('##0,00', {
      reverse: true,
      placeholder: "0,00",
      maxValue: 100
    })
  });

  $('body').on('input', '.numeric', function() {
    $(this).mask("0000000000000000000", {reverse: true})
  });

  $('body').on('click', '.save-form', function() {
    const target = $(this).data('target')
    let approval_form = $('#deal-approval-form')
    if (approval_form.find('#ready_for_save').val()) {
      approval_form.find(':submit').trigger('click');
      setTimeout(function(){
        if (target) $(target).find(':submit').trigger('click');
      }, 3000)
    }else{
      if (target) $(target).find(':submit').trigger('click');
    }

  })

  $('body').on('change', '.percentage', function() {
    $(this).mask('99,99%', { reverse: true })
  });

  $('body').on('click', '.dropdown-group', function() {
    const $dropdownList = $(this).find('.dropdown-list')
    $dropdownList.toggleClass('show')
  })

  $('body').on('click', '.history-back', function() {
    history.back()
  })

  $('body').on('shown.bs.modal', '#modal-client-welcome', function (e) {
    console.log('funciona showq')
  })

  $('body').on('hidden.bs.modal', '#modal-client-welcome', function (e) {
      console.log('funciona')
      $.ajax({
          url: '/close_welcome',
          dataType: 'script',
          method: 'GET'
      })
  })

  $('body').on('focus', '.datetimepicker', function() {
    if (!$(this).attr('readonly')) {
      initFlatpickrElement($(this));
    }
  })

  $('body').on('click', '.change-app-view-container button', function() {
    $(this).parents('.change-app-view-container').remove()
  })

  $('body').on('change', '.change-other-input', function() {
    const isCheckboxInput = $(this).attr('type') === 'checkbox'
    const isChecked = $(this).is(':checked')
    const targetId = $(this).data('input-target')
    const value = isCheckboxInput && !isChecked ? '' : $(this).val()
    $(targetId).val(value).trigger('change')
  })

  $('body').on('click', '.disabled', function(e) {
    const noPrevent = $(this).data('no-prevent-on-click')
    if (noPrevent) return
    e.preventDefault()
    e.stopPropagation()
  })

  $('body').on('change', '.disabled', function(e) {
    const noPrevent = $(this).data('no-prevent-on-change')
    if (noPrevent) return
    e.preventDefault()
    e.stopPropagation()
  })

  $('.datetimepicker').each((_index, element) => initFlatpickrElement($(element)))

  $('body').on('change', '.datetimepicker[data-plugin="month"]', function(e) {
    e.stopPropagation()
    const { selectedDates: [ selectedDate ] } = this._flatpickr
    const targetId = $(this).data('target')
    const $target = $(targetId)

    $target.val(selectedDate ? new Date(selectedDate).toISOString() : null).trigger('change')
  })

  $('body').on('keyup', '.number_field', function() {
    const value = $(this).val().replaceAll(',', '.')

    if (!value) return

    const lastCharacter = value.slice(-1)
    const lastCharacterIsNaN = isNaN(Number(lastCharacter))
    const correctValue = value.slice(0, value.length - 1)
    const valueIsNaN = isNaN(Number(value))

    if (!lastCharacterIsNaN || !valueIsNaN) return

    $(this).val(correctValue)
  })

  $('body').on('click', '.clear-form', function() {
    const formId = $(this).data('target')
    const noSubmit = $(formId).data('no-submit')
    $(formId)[0].reset()
    $(formId).find('.datetimepicker-range').each((_index, elem) => elem._flatpickr.clear())
    $(formId).find('.js-basic-single').val('').trigger('change')
    if (noSubmit) $(formId).find(':submit').trigger('click')
  })

  $('body').on('hidden.bs.modal', function() {
    $('.js-basic-single').select2();
  });

  $('body').on('click', '.submit-form', function(){
    $(this).parent().find("input[type='submit']").trigger('click')
  })


  $('body').on('click', '.loader-delete', function(e) {
    e.preventDefault();

    let $link = $(this);
    let message = '¿Estas seguro de eliminar este archivo?';

    if (confirm(message)) {
      $('.loader').fadeIn();

      $.ajax({
        url: $link.attr('href'),
        type: 'DELETE',
        dataType: 'script',
        error: function(xhr, status, error) {
          console.log('Error al eliminar el archivo:', error);
        }
      });
    }
  });


  $('body').on('click', '.get-reports-entity', function () {
    let url = $(this).data('url');
    console.log(url)
    $.ajax({
      url: url,
      method: 'get',
      dataType: 'script',
      data: { company_id: $(this).data('companyId') }
    })
  })

})