$( document ).on('turbolinks:load', common_events)

function common_events(){
    $('.signature-check').on('click', function() {
        let signature_status = $(this).data('status');
        let is_checked = $(this).is(':checked');
        let inputs_different_status = $(`.signature-check:not([data-status=${signature_status}])`);
        $('.signature-check').each(function() {
            $(this).removeClass('disabled');
        });
        if (is_checked) {
            inputs_different_status.each(function() {
                $(this).prop('checked', false);
                $(this).addClass('disabled');
            });
        } else {
            let any_checked = $('.signature-check:checked').length > 0;
            if (!any_checked) {
                $('.signature-check').each(function() {
                    $(this).removeClass('disabled');
                });
            }
        }
    });

    $('.signature-check').on('change', function() {
        let any_checked = $('.signature-check:checked').length > 0;
        let signature_status = $(this).data('status-signature');
        if(any_checked){
            $('#massive-signed').removeClass('disabled');
            $('#message-area-signature').removeClass('d-none');
            $('#message-area-signature').find('span').html(signature_status);
        }else{
            $('#massive-signed').addClass('disabled');
            $('#message-area-signature').addClass('d-none');
        }
    });

    $('#massive-signed').on('click', function() {
        let selected_ids = $('.signature-check:checked').map(function() {
            return $(this).data('signature');
        }).get();

        let url = $(this).data('url');

        $.ajax({
            url: url,
            method: 'GET',
            dataType: 'script',
            data: { ids: selected_ids },
            error: function(error) {
                console.log('Error:', error);
            }
        });
    });

}