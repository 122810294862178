import customer from './consumer';

const taxFolderLoaded = (companyId) => {

	if (window.location.pathname === `/clientes/${companyId}`){
		$.ajax({
			type: "GET",
			url: `${window.location.origin}/companies/${companyId}/tax_folder/index`,
			dataType: "script"
		});
	}

}

const updateDebtorsTabReports = (data) => {
	const {
		message,
		title,
		status,
		url,
	} = data;

	$modals[status]({ message, title, url, close: Boolean(url) })
}

const validateSiiCredential = (data) => {
	const {
		title,
		message,
		company_id,
		status
	} = data;

	$modals[status]({ title, message })

	if (status === 'success') {
		console.log(company_id)
		$(`#sii-warning-${ company_id }`).remove()
	}
}
const getDocumentsSii = (companyId) => {
	$.ajax({
		url: '/clients/dashboard/data_client',
		method: 'GET',
		dataType: 'script',
		data:{company_id:companyId}
	})
}

const requestLastTwoYearsClientTransferredInvoices = (data) => {
	const {title, message, status, type} = data;

	$modals[status]({ title, message })

	if (type === 'end_request') {
		$('#request_last_two_years_client_transferred_invoice_button').removeClass('disabled')
	}

}

customer.subscriptions.create({ channel: 'CompaniesChannel' }, {
	received(data) {
		if (data.action === 'tax_folder_loaded') taxFolderLoaded(data.company_id)
		if (data.action === 'update_debtors_tab_reports') updateDebtorsTabReports(data)
		if (data.action === 'validate_sii_credential') validateSiiCredential(data)
		if (data.action === 'get_documents_sii') getDocumentsSii(data.company_id)
		if (data.action === 'request_last_two_years_client_transferred_invoices') requestLastTwoYearsClientTransferredInvoices(data)
	}
})